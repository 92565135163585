@use 'styles/vapor' as vapor;

$max-content-width: 256px;
$icon-height: 193px;

article.AutoShipCTA {
  position: relative;
  background: vapor.color('jade', 'background-100');
  display: flex;
  align-items: center;
}

.content {
  position: relative;
  z-index: 1;
  max-width: $max-content-width;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.linkButton {
  width: fit-content;
}

.icon {
  position: absolute;
  bottom: vapor.spacing('none');
  right: vapor.spacing('tight');

  svg {
    @include vapor.icon-size('custom-height', $icon-height);
  }

  path {
    fill: vapor.color('jade', 'dark-10');
  }
}
