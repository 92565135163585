@use 'styles/vapor' as vapor;

$total-width: 56px;
$total-height: 246px;
$device-offset: 133px;
$pod-connected-offset: 32px;
$pod-disconnected-offset: 8px;

.DeviceHero {
  border-bottom: 0.5px solid transparent;
  position: relative;
  width: $total-width;
  height: $total-height;
  overflow: hidden;

  &.masked {
    opacity: 0.5;
  }
}

.juulDevice {
  display: flex;
  top: $device-offset;
  position: absolute;
}

.juulPod {
  z-index: vapor.z-index('component');
  display: flex;
  position: absolute;

  &.podConnected {
    top: $pod-connected-offset;
    opacity: 1;
    transition: top 0.75s ease-out, opacity 0.75s ease-out;
  }

  &.podDisconnected {
    top: $pod-disconnected-offset;
    opacity: 0;
    transition: top 0.5s, opacity 0.5s;
  }
}
