@use 'styles/vapor' as vapor;

$icon-size: 28px;
$details-max: 136px;

.AutoShip {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    gap: vapor.spacing('tighter');
    margin-bottom: vapor.spacing('base-loose');
  }

  .icon svg {
    @include vapor.icon-size('custom-height', $icon-size);
  }

  .details {
    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, $details-max));
    row-gap: vapor.spacing('base-loose');
    column-gap: vapor.spacing('base');
  }

  .secondRow {
    margin-bottom: vapor.spacing('base');
  }

  .errorMessage {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .linkButton {
    width: fit-content;
  }
}
