@use 'styles/vapor' as vapor;

$star-size-base: 46px;
$star-size-small: 36px;
$star-rating-queries: (
  base:  ( min-width:  262px ),
);

.StarRating {
  container: star-rating / inline-size;
  width: 100%;

  .stars {
    display: flex;
    margin: auto;
    justify-content: center;
    height: $star-size-small;
  
    @include vapor.respond-to-container('base', $star-rating-queries) {
      height: $star-size-base;
    }
  }

  .starInput {
    @include vapor.visually-hidden();
  }

  .starLabel {
    display: inline-block;
    padding: 0 vapor.spacing('extreme-tight');
    width: $star-size-small;

    svg {
      @include vapor.icon-size('custom-height', $star-size-small);
    }

    @include vapor.respond-to-container('base', $star-rating-queries) {
      padding: 0 vapor.spacing('extra-tight');
      width: $star-size-base;

      svg {
        @include vapor.icon-size('custom-height', $star-size-base);
      }
    }
  }

  .star:first-of-type .starLabel {
    padding-left: 0;
  }
  .star:last-of-type .starLabel {
    padding-right: 0;
  }
}
