@use 'styles/vapor' as vapor;

$textarea-height: 84px;
$max-left-desktop-grid-width: 262px;
$feedback-form-queries: (
  desktop:  ( min-width:  600px ),
);

.FeedbackForm > .formWrap {
  container: feedback-form / inline-size;
  position: relative;

  .titlePrompt {
    text-align: center;

    @include vapor.respond-to-container('desktop', $feedback-form-queries) {
      text-align: left;
    }
  }

  .inputFieldArea {
    display: flex;
    flex-direction: column;
    gap: vapor.spacing('base-loose');

    .inputField {
      display: flex;
      flex-direction: row;
      justify-content: left;
      height: 100%;

      .icon svg {
        @include vapor.icon-size('medium');
      }
    
      .textareaWrapper {
        flex-grow: 1;
      }
      
      .textareaInput {
        min-height: $textarea-height;
      }
    }
  }

  .formGrid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: vapor.spacing('base-loose');

    &.isSubmitted {
      visibility: hidden;
    }

    @include vapor.respond-to-container('desktop', $feedback-form-queries) {
      grid-template-columns: minmax(auto, $max-left-desktop-grid-width) 1fr;
      column-gap: vapor.spacing('extra-extra-loose');
    
      // top section
      .starRatingArea {
        grid-row-start: 1;
        grid-row: span 1 / span 1;
        grid-column: span 1 / span 1;
      }

      // middle section
      .inputFieldArea {
        grid-row-start: 1;
        grid-row: span 2 / span 2;
        grid-column-start: 2;
      }

      // bottom section
      .submissionArea {
        grid-row-start: 2;
        grid-row: span 1 / span 1;
        grid-column: span 1 / span 1;
      }
    }
  }

  .submittedOverlay {
    position: absolute;
    background-color: vapor.color('white');
    top: vapor.spacing('none');
    bottom: vapor.spacing('none');
    left: vapor.spacing('none');
    right: vapor.spacing('none');
    z-index: vapor.z-index('overlay');
  }
}
