@use 'styles/vapor' as vapor;

$content-max-width: 360px;

article.SurveyCard {
  display: flex;
  flex-direction: column;

  // For carousel slides
  border: 0.5px solid vapor.color('titanium', 'base-30');
  box-sizing: border-box;
  box-shadow: none;
  
  .content {
    margin: auto;
    width: 100%;
    max-width: $content-max-width;
  }
 
  .options {
    display: flex;
    flex-direction: column;
    gap: vapor.spacing('base');
  }
}