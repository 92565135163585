@use 'styles/vapor' as vapor;

$text-mask: vapor.color('steel', 'base-50'); 
$info-box-height: 129px;
$caption-base-bold-line-height: 18px;
$caption-small-line-height: 13px;

.InfoBox {
  background-color: vapor.color('white');
  border-radius: vapor.border-radius('giant');
  box-shadow: vapor.shadow('drop-shadow-0');

  display: flex;
  flex-direction: column;
  width: 100%;
  height: $info-box-height;
  box-sizing: border-box;
  overflow: hidden;

  & > .textGroup {
    margin-top: auto;
    padding-top: vapor.spacing('tight');
  }

  .heading:empty {
    height: $caption-base-bold-line-height;
    box-sizing: content-box;
  }

  .subHeading:empty {
    height: $caption-small-line-height;
    box-sizing: content-box;
  }

  .description:empty {
    height: calc($caption-small-line-height * 2);
    box-sizing: content-box;
  }

  .hideOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.color {
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: $text-mask; 
  -moz-text-fill-color: $text-mask;
}
