@use 'styles/vapor' as vapor;

$section-queries: (
  twoColumns:  ( min-width:  520px ),
);
$desktop-row-gap: 48px;
$min-row-height: 220px;
$span-full-screen: 2;
$span-half-screen: 1;
$card-translateY-0: translateY(25px);
$card-translateY-100: translateY(0);

.Section {
  container: section / inline-size;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: vapor.spacing('base');
  }

  .heading {
    @include vapor.typography('headline-h4', 'mobile');
  
    @include vapor.respond-to('tablet') {
      @include vapor.typography('headline-h4', 'base');
    }
  }

  .animateContent {
    animation: fade-in-slide-up 1s;
  }

  .body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax($min-row-height, auto);
    gap: vapor.spacing('base-loose');
  
    > * {
      grid-column: span $span-full-screen;
    }
  
    @include vapor.respond-to-container('twoColumns', $section-queries) {
      row-gap: $desktop-row-gap;
      column-gap: vapor.spacing('base-loose');
    
      > * {
        grid-column: span $span-half-screen;
      }
  
      > :last-child {
        &:nth-child(odd) {
          grid-column: span $span-full-screen;
        }
        &:nth-child(even) {
          grid-column: span $span-half-screen;
        }
      }
    }
  }
}

@keyframes fade-in-slide-up {
  0% {
    opacity: 0;
    transform: $card-translateY-0;
  }

  100% {
    opacity: 1;
    transform: $card-translateY-100;
  }
}
