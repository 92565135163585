@use 'styles/vapor' as vapor;

article.NoOrders {
  background: vapor.color('steel', 'base-5');
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.linkButton {
  width: fit-content;
}
