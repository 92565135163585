@use 'styles/vapor' as vapor;

// container queries
$stores-near-you-queries: (
  base:  ( min-width:  472px ),
);

// z-indexes
$z-index-top: 2;
$z-index-mid: 1;

// location icons container width
$location-icons-small-max-width: 32px;
$location-icons-base-max-width: 208px;

// black pin icon variables
$black-pin-height: 64px;
$black-pin-position: (
  'pin1': (
    right: -12px,
    top: 20px,
  ),
  'pin2': (
    right: 18px,
    top: 140px,
  ),
);

// blue pin icon variables
$blue-pin-height: 76px;
$blue-pin-position: (
  'base': (
    right: 58px,
    top: 74px,
  ),
  'small': (
    right: -60px,
    top: 20px,
  ),
);
 
// location icon variables
$location-position: (
  'base': (
    right: 160px,
    top: 130px,
  ),
  'small': (
    right: -25px,
    top: 160px,
  ),
);

// background map variables
// the adjusted-map-width is also the absolute max-width of the component
$map-width: 1282px;
$adjusted-map-width: calc($map-width - 6px);
$map-position: (
  'base': (
    transform: translate(100%, -50%),
    top: 85%,
    right: $adjusted-map-width,
  )
);

article.StoresNearYou {
  container: stores-near-you / inline-size;
  position: relative;
  background-color: vapor.color('blue', 'background-100');
  display: flex;
  justify-content: space-between;
  padding: vapor.spacing('base-semi-loose');
  max-width: $adjusted-map-width;
}

.content {
  position: relative;
  z-index: $z-index-top;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @include vapor.respond-to('tablet') {
    margin: vapor.spacing('extra-tight');
  }
}

.linkButton {
  width: fit-content;
  white-space: nowrap;
}

.locationIcons {
  height: auto;
  width: 100%;
  z-index: $z-index-mid;
  position: relative;
  max-width: $location-icons-small-max-width;
  margin: vapor.spacing('extra-tight');

  @include vapor.respond-to-container('base', $stores-near-you-queries) {
    max-width: $location-icons-base-max-width;
  }

  & > div {
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .blackPin {
    display: none;

    @include vapor.respond-to-container('base', $stores-near-you-queries) {
      display: flex;
    }

    svg {
      @include vapor.icon-size('custom-height', $black-pin-height);
    }

    &.pin1 {
      @include vapor.get-state($black-pin-position, 'pin1');
    }

    &.pin2 {
      @include vapor.get-state($black-pin-position, 'pin2');
    }
  }

  .bluePin {
    @include vapor.get-state($blue-pin-position, 'small');

    @include vapor.respond-to-container('base', $stores-near-you-queries) {
      @include vapor.get-state($blue-pin-position, 'base');
    }
    
    svg {
      @include vapor.icon-size('custom-height', $blue-pin-height);
    }
  }

  .location {
    @include vapor.get-state($location-position, 'small');

    @include vapor.respond-to-container('base', $stores-near-you-queries) {
      @include vapor.get-state($location-position, 'base');
    }
  }
}

.mapBackground {
  position: absolute;
  @include vapor.get-state($map-position, 'base');

  svg {
    @include vapor.icon-size('custom-width', $map-width);
  }
}
