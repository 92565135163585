@use 'styles/vapor' as vapor;

// puff gauge css config
$base-background-color: vapor.color('white');
$gauge-height: 24px;
$outer-border-width: 1px;
$inner-border-width: 1.5px;
$inner-border-radius: 11px;
$bar-border-radius: 9.5px;
$spacer-width: calc(25% - 0.75px);
$spacer-height: calc(100% + 2px);
$tick-width: 1px;
$bottom-tick-opacity: 0.86;
$prog-wrap-size: calc(100% - 5px);
$prog-bar-z-index: 1;
$top-scale-z-index: 2;

// parent component

.PuffGauge {
  height: $gauge-height;
  min-height: $gauge-height;
  width: 100%;
  border-radius: vapor.border-radius('extra-large');
  padding: $outer-border-width;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  box-sizing: border-box;
}

// base layer (bottom layer of gauge)

.innerBorder {
  height: 100%;
  width: 100%;
  border: $inner-border-width solid $base-background-color;
  border-radius: $inner-border-radius;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $base-background-color;

  &.hasScale {
    background-color: transparent;
  }
}

.loader {
  border-radius: $bar-border-radius;
  overflow: hidden;
}

.errorBar {
  width: 100%;
  height: 100%;
  border-radius: $bar-border-radius;
  overflow: hidden;
  background: repeating-linear-gradient(
    -45deg,
    vapor.color('rose', 'base-100'),
    vapor.color('rose', 'base-100') 1px,
    vapor.color('white', 'base-100') 1px,
    vapor.color('white', 'base-100') 2px
  );
}

.spacer {
  background: $base-background-color;
  width: $spacer-width;
  height: $spacer-height;

  &:first-of-type {
    border-top-left-radius: $bar-border-radius;
    border-bottom-left-radius: $bar-border-radius;
  }
  &:last-of-type {
    border-top-right-radius: $bar-border-radius;
    border-bottom-right-radius: $bar-border-radius;
  }
}

.bottomScaleTick {
  position: relative;
  background-color: $base-background-color;
  opacity: $bottom-tick-opacity;
  width: $tick-width;
  height: 100%;

  &.noData {
    opacity: 0;
  }
}

// top layer (active progress bar layer)

.progressBarWrap {
  position: absolute;
  height: $prog-wrap-size;
  width: $prog-wrap-size;
  border-radius: $bar-border-radius;
  overflow: hidden;
}

.progressBarIndicator {
  height: 100%;
  border-radius: vapor.border-radius('extra-giant');
  /* this will do the magic (proper width of the prog-bar) */
  -webkit-mask: linear-gradient($base-background-color 0 0);
  mask: linear-gradient($base-background-color 0 0);
  z-index: $prog-bar-z-index;
  transition: width ease 0.3s;
}

.progressBarFill {
  position: absolute;
  z-index: $prog-bar-z-index;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.topScale {
  position:absolute;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: $top-scale-z-index;
}

.topScaleTick {
  position: relative;
  background-color: $base-background-color;
  width: $tick-width;
  height: 100%;
  z-index: $top-scale-z-index;
}
