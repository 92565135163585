@use 'styles/vapor' as vapor;

.DeviceDash {
  width: 100%;
  
  &.size4, &.size5 {
    display: flex;
    gap: vapor.spacing('base');
  }
}

.deviceHero {
  flex: none;
}

.backgroundTile {
  flex: auto;
  overflow: hidden;
}
