@use 'styles/vapor' as vapor;

// battery css config
$icon-height: 19.4px;
$battery-height: 24px;
$battery-end-height: 8px;
$battery-end-width: 2px;
$outer-border-radius: 7px;
$inner-border-radius: 4.5px;
$battery-end-border-radius: 2px;
$white-padding-width: 1.5px;
$outer-border-width: 1px;
$battery-gauge-gap: 1px;

.BatteryGauge {
  display: inline-flex;
  gap: $battery-gauge-gap;
  align-items: center;
  vertical-align: top;
}

.batteryMain {
  box-sizing: border-box;
  position: relative;
  height: $battery-height;
  border: $outer-border-width solid vapor.color('titanium', 'base-75');
  border-radius: $outer-border-radius;
  padding: $white-padding-width;
  background-color: vapor.color('white');

  &.loading {
    border-color: vapor.color('titanium', 'base-30');
  }
}

.batteryEnd {
  width: $battery-end-width;
  height: $battery-end-height;
  background: vapor.color('titanium', 'base-75');
  border-radius: 0 $battery-end-border-radius $battery-end-border-radius 0;

  &.loading {
    background: vapor.color('titanium', 'base-30');
  }
}

.loader {
  border-radius: $inner-border-radius;
  overflow: hidden;
}

.batteryLevelWrap {
  background: transparent;
  height: 100%;
  width: 100%;
  border-radius: $inner-border-radius;
  overflow: hidden;
}

.batteryLevel {
  height: 100%;
  border-radius: $inner-border-radius;
  transition-property: width, border-radius, background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  background-color: vapor.color('titanium', 'base-75');

  &.charging {
    background-color: vapor.color('lime', 'base-100');
  }

  &.lowBattery {
    background-color: vapor.color('rose', 'base-100');
  }
}

.chargingIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  svg {
    @include vapor.icon-size('custom-height', $icon-height);
  }
}
