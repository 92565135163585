@use 'styles/vapor' as vapor;

$mobile-bottom-padding: 64px;
$tablet-bottom-padding: 120px;
$max-page-width: 916px;

.Home {
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  max-width: $max-page-width;
  padding-top: vapor.spacing('extra-loose');
  padding-bottom: $mobile-bottom-padding;

  @include vapor.respond-to('tablet') {
    padding-top: vapor.spacing('extreme-loose');
    padding-bottom: $tablet-bottom-padding;
  }
}
