@use 'styles/vapor' as vapor;

.SectionToggle.SectionToggle {
  @include vapor.typography('button', 'base');
  text-transform: uppercase;
  color: vapor.color('ink', 'base-100');
  display: flex;
  gap: vapor.spacing('tighter');
  padding-left: vapor.spacing('extreme-tight');

  &:focus-visible {
    outline: 1.5px solid vapor.color('ink', 'base-50');
    outline-offset: vapor.spacing('extra-tight');
    border-radius: vapor.border-radius('extreme-small');
  }

  &:disabled {
    color: vapor.color('steel', 'base-30');
    pointer-events: none;
    cursor: default;
  }
}
