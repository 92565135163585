@use 'styles/vapor' as vapor;

// these have a difference of 8px to account for changes in the padding
$top-section-min-height-mobile: 256px;
$top-section-min-height-tablet: 264px;

article.ClaimsCard {
  display: flex;
  flex-direction: column;
  padding: vapor.spacing('none');

  // For carousel slides
  border: 0.5px solid vapor.color('titanium', 'base-30');
  box-sizing: border-box;
  box-shadow: none;

  .heading {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .topSection {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: $top-section-min-height-mobile;
    padding: vapor.spacing('base-semi-loose');
    gap: vapor.spacing('base');
    flex-grow: 1;

    @include vapor.respond-to('tablet') {
      padding: vapor.spacing('base-loose');
      min-height: $top-section-min-height-tablet;
    }
  }

  .bottomSection {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: vapor.spacing('base-semi-loose');
    background-color: vapor.color('snow', 'base-100');
    padding: vapor.spacing('base-semi-loose');

    @include vapor.respond-to('tablet') {
      padding: vapor.spacing('base-loose');
      gap: vapor.spacing('base-loose');
    }
  }

  .claim {
    display: flex;
    flex-direction: column;
    gap: vapor.spacing('tight');
  }
}
