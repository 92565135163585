@use 'styles/vapor' as vapor;

.Connected {
  display: flex;
  gap: vapor.spacing('base-semi-loose');
  flex-direction: column;
  justify-content: left;
  padding-top: vapor.spacing('base');

  &.size1 {
    width: 100%;
    padding-top: vapor.spacing('base-tight');
  }
  
  &.size5, &.size4 {
    flex-direction: row-reverse;
  }
}

// Button Group

.buttonGroup {
  display: flex;
  gap: vapor.spacing('base-tight');
  width: fit-content;
}

button.unlockButton {
  background-color: vapor.color('red', 'dark-100');
  
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: vapor.color('red', 'dark-90');
      transition: background-color 0.3s ease;
    }
  }

  &:focus-visible {
    background-color: vapor.color('red', 'dark-75');
    transition: background-color 0.3s ease;
  }
  
  &:active {
    background-color: vapor.color('red', 'dark-75');
  }
}

// InfoBox Group

$info-box-small: 148px;
$info-box-base: 160px;
$info-box-large: 180px;
$info-box-gap: vapor.spacing('base-tight');

@function getInfoBoxGroupWidth($infoBoxWidth, $gap: $info-box-gap) {
  @return (2 * $infoBoxWidth) + $gap
};

.infoBoxGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $info-box-gap;
  min-width: $info-box-small;

  &.size2, &.size3, &.size4, &.size5 {
    flex-direction: row;
    min-width: getInfoBoxGroupWidth($info-box-small);
  
    .infoBox {
      flex: 1;
    }
  }

  &.size2 {
    max-width: getInfoBoxGroupWidth($info-box-small);
  }

  &.size3, &.size4 {
    max-width: getInfoBoxGroupWidth($info-box-base);
  }

  &.size5 {
    max-width: getInfoBoxGroupWidth($info-box-large);
  }
}
