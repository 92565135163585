@use 'styles/vapor' as vapor;

$max-content-width: 256px;
$icon-height: 193px;

article.InsightsPending {
  position: relative;
  background-color: vapor.color('graphica', 'background-100');
  display: flex;
  align-items: center;
}

.message {
  position: relative;
  z-index: 1;
  max-width: $max-content-width;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.icon {
  position: absolute;
  bottom: vapor.spacing('none');
  right: vapor.spacing('none');

  svg {
    @include vapor.icon-size('custom-height', $icon-height);
  }
  
  path {
    fill: vapor.color('graphica', 'base-10');
  }
}
