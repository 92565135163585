@use 'styles/vapor' as vapor;

.OverlayButton {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: vapor.spacing('none');
  z-index: vapor.z-index('overlay');
  border-radius: vapor.border-radius('extra-giant');
  background-color: vapor.color('titanium', 'base-10');

  &:hover, &:focus-visible, &:active {
    background-color: vapor.color('titanium', 'base-15');
    transition: background-color 0.3s ease;
  }

  .extension {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
  }
}
