@use 'styles/vapor' as vapor;

.heading {
  display: flex;
  color: vapor.color('titanium', 'base-100');

  &.masked {
    color: vapor.color('titanium', 'base-50');
  }

  &.sizeSmall {
    @include vapor.typography('headline-h3', 'mobile');
  }

  &.sizeBase {
    @include vapor.typography('headline-h3', 'base');
  }

  .headingText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .headingTextColor {
    background-size: 100%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: vapor.color('steel', 'base-75');
    -moz-text-fill-color: vapor.color('steel', 'base-75');
  }

  .lockIcon {
    margin-left: vapor.spacing('extra-tight');
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.subHeading {
  color: vapor.color('titanium', 'base-50');
  @include vapor.typography('subtitle', 'small');

  &.masked {
    color: vapor.color('titanium', 'base-30');
  }

  .subHeadingText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .subHeadingTextColor {
    background-size: 100%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: vapor.color('steel', 'base-30');
    -moz-text-fill-color: vapor.color('steel', 'base-30');
  }
}
