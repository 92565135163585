@use 'styles/vapor' as vapor;

$max-column-width: 136px;
$overflow-card-height: 90px;
$overflow-card-width: 60px;

.MostRecent {
  .details {
    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, $max-column-width));
    column-gap: vapor.spacing('base');
    margin-bottom: vapor.spacing('base-semi-loose');
  }

  .content {
    display: flex;
    gap: vapor.spacing('extra-tight');
    width: 100%;
  }

  .overflow {
    height: $overflow-card-height;
    min-width: $overflow-card-width;
    box-sizing: border-box;
    margin-top: vapor.spacing('tighter');
    background: vapor.color('snow', 'base-100');
    @include vapor.typography('headline-h3', 'mobile');
    color: vapor.color('ink', 'base-50');
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: vapor.border-radius('extra-small');
  }
}
