@use 'styles/vapor' as vapor;

$link-margin-right: -5px;

.SectionLink.SectionLink {
  display: inline-flex;
  @include vapor.typography('button');
  text-transform: uppercase;
  color: vapor.color('ink', 'base-100');
  padding-left: vapor.spacing('tighter');
  margin-right: $link-margin-right;

  &:hover {
    color: vapor.color('ink', 'base-50');
  }

  &:focus-visible {
    outline: 1.5px solid vapor.color('ink', 'base-50');
    outline-offset: vapor.spacing('extreme-tight');
    border-radius: vapor.border-radius('extreme-small');
  }

  &.disabled {
    color: vapor.color('steel', 'base-30');
    pointer-events: none;
    cursor: default;
  }
    
  .linkIcon svg {
    @include vapor.icon-size('small');
  }
}
