@use 'styles/vapor' as vapor;

// todo update or remove min-widths when breakpoints are final
// update heights <------- in integration PR

$background-card-sizing: (
  1: (
    height: 414px,
    // min-width: 247px,
    padding: vapor.spacing('base'),
  ),
  2: (
    height: 292px,
    // min-width: 364px,
    padding: vapor.spacing('base-loose'),
  ),
  3: (
    height: 298px,
    // min-width: 381px,
    padding: vapor.spacing('base-loose'),
  ),
  4: (
    height: 246px,
    // min-width: 564px,
    padding: vapor.spacing('base-loose'),
  ),
  5: (
    height: 246px,
    // min-width: 631px,
    padding: vapor.spacing('base-loose'),
  )
);

.BackgroundTile {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: vapor.border-radius('extra-giant');
  border: 0.5px solid transparent;
  box-sizing: border-box;

  &.hasShadow {
    box-shadow: vapor.shadow('drop-shadow-1');
  }
  
  @each $size, $sizeMap in $background-card-sizing {
    &.size#{$size} {
      @include vapor.get-state($background-card-sizing, $size)
    }
  }
}
